
header {
    padding: 15px 20px;
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    background-color: var(--a-header);
    font-family: $font_heading;

    @media ($breakpoint_MD) {
        padding: 15px 15px;
    }


    .main_continer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .left-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media ($breakpoint_LG) {
        width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        .navbar-logo {
            width: 150px;
            height: 100%;
            display: block;

            @media ($breakpoint_MD) {
                width: 150px;
            }

            img {
                object-fit: contain;

                &:first-child{
                    display: none;
                }
            }
        }

        .token__sx {
            display: flex;
            align-items: center;
            background-color: var(--header__token_sx);
            border: 1px solid var(--header__token_sx_border);
            margin-left: 20px;
            padding: 7px 10px;
            border-radius: 10px;
            color: var(--txt-black);
            backdrop-filter: blur(15px);
            white-space: nowrap;

            img {
                width: 30px;
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
                margin-left: 10px;
            }
        }

       
    }




    .right-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        @media ($breakpoint_LG) {
            display: none;
        }

        .nav {

            .nav-item {
                font-family: 'Lato', sans-serif;


                .nav-link {
                    color: var(--txt-black);
                    font-size: 17px;
                    opacity: 0.8;

                }

                .dropdown__sx {
                    position: relative;
                    z-index: 1;


                    &:hover {

                        .dropdown_menu_sx {
                            opacity: 1;
                            visibility: visible;
                            transform: none;
                        }

                    }

                    .nav-link {
                        margin-bottom: 0;
                        cursor: pointer;

                    }

                    .dropdown_menu_sx {
                        position: absolute;
                        top: 38px;
                        right: 0;
                        padding: 28px 7px 20px;
                        width: 250px;
                        height: auto;
                        z-index: -1;
                        overflow: hidden;
                        visibility: hidden;
                        opacity: 0;
                        transform: translate3d(0, 18px, 0);
                        transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .4s cubic-bezier(.1, .76, .37, 1.19);




                        .dropdown-ol {
                            padding: 0;
                            margin: 0;
                            padding: 10px !important;
                            background-color: var(--dropdown__bg);
                            border: 1px solid var(--border_cc);
                            border-radius: 10px;
                            box-shadow: var(--a-header-box-shadow);


                            .dropdown-item {
                                display: inline-block;
                                color: var(--pragraph_color);
                                padding: 10px;
                                position: relative;
                                z-index: 1;
                                border-radius: 10px;


                                &:hover {
                                    background-color: var(--dropdown_item_hover);


                                    &::after {
                                        width: 100%;
                                    }
                                }

                                &::after {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    width: 0%;
                                    height: 1px;
                                    background-color: var(--home-primary);
                                    transition: all 0.4s cubic-bezier(.1, .76, .37, 1.19);

                                }

                            }


                        }
                    }

                }
            }

        }

        .right__end__sx {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            margin-left: 20px;

            .dropdown__tk {
                position: relative;
                z-index: 1;


                &:hover {

                    .dropdown_menu_sx {
                        opacity: 1;
                        visibility: visible;
                        transform: none;
                    }

                }

                .tk__logo {
                    width: 50px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .dropdown_menu_sx {
                    position: absolute;
                    top: 38px;
                    right: 0;
                    padding: 28px 7px 20px;
                    width: 400px;
                    height: auto;
                    z-index: -1;
                    overflow: hidden;
                    visibility: hidden;
                    opacity: 0;
                    transform: translate3d(0, 18px, 0);
                    transition: opacity .3s cubic-bezier(.165, .84, .44, 1), transform .4s cubic-bezier(.1, .76, .37, 1.19);

                    .nav___isd {
                        background-color: var(--dropdown__bg);
                        border: 1px solid var(--border_cc);
                        border-radius: 10px;
                        padding: 14px;
                        box-shadow: var(--a-header-box-shadow);
                        list-style-type: none;

                        li {
                            margin-bottom: 10px;

                            h4 {
                                font-weight: 600;
                                margin-bottom: 30px;

                            }

                            p {
                                margin-bottom: 5px;
                                font-size: 15px;
                                text-transform: capitalize;

                                &:last-child {
                                    border: 1px solid var(--black__border);
                                    margin-bottom: 0;
                                    padding: 10px;
                                    border-radius: 10px;
                                }
                            }

                            .btn {
                                background: var(--btn_gradient);
                                padding: 10px;
                                text-align: center;
                                display: block;
                                width: 70%;
                                margin: auto;
                                white-space: nowrap;
                                border-radius: 30px;
                                margin-top: 20px;
                                color: var(--txt-white);
                            }
                        }

                    }





                }

            }

            .theme__button {
                background-color: var(--header__token_sx);
                border: 1px solid var(--header__token_sx_border);
                border-radius: 5px;
                padding: 5px 8px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--txt-black);

            }





        }

    }

    .toggle-button {
        color: var(--txt-white);
        display: none;

        @media ($breakpoint_LG) {
            display: block;
        }
    }

}

$HomeMenu__header: 100%;

@keyframes HomeMenuAnimation {
    0% {
        transform: translateY(-$HomeMenu__header);
    }

    100% {
        transform: translateY(0px);
    }
}


@keyframes HomeMenuAnimationLeaveAnimation {
    0% {
        transform: translateY(0);

    }

    100% {
        transform: translateY($HomeMenu__header);
    }
}

.HomeMenu__bv {
    height: 100vh;
    background-color: var(--sidebar-bg) !important;
    position: fixed !important;
    top: 0;
    left: 0px !important;
    max-width: 100% !important;
    width: $HomeMenu__header;
    margin: 0px !important;
    transition: 0.5s;
    z-index: 12;
    overflow: auto;
    border-radius: 0px !important;
    border: 0px !important;
    padding: 0 !important;


    @media($breakpoint_ESM) {
        width: 260px;
    }

    .phone-view-header {

        .logo__sc {
            // background-color: #3f3a3a;
            background-color: #000;
            display: block;
            padding: 10px;

            img {
                width: 150px;
            }
        }

        .nav {
            padding: 20px;
            --sidebar__hodc: #3a393829;
            --sidebar__border_radius: 10px;


            .nav-link {
                color: var(--txt-white);
                background-color: var(--sidebar__hodc);
                border-radius: var(--sidebar__border_radius);
                margin-bottom: 10px;
            }

            .accordion {

                .accordion-item {
                    margin: 10px 0px;
                    border: none;
                    background-color: transparent;

                    .accordion-header {

                        .accordion-button {
                            padding: 0px;
                            background-color: var(--sidebar__hodc);
                            border-radius: var(--sidebar__border_radius);
                            box-shadow: none;

                            a,
                            span {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                text-decoration: none;
                                width: 100%;
                                padding: 15px 15px;
                                color: var(--txt-white);
                                font-size: 15px;
                                font-weight: 500;

                                svg {
                                    color: var(--primary-color);
                                    font-size: 20px;
                                }
                            }

                            .drop_link {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                text-decoration: none;
                                width: 100%;
                                padding: 15px 15px;
                                color: var(--txt-white);
                                font-size: 15px;
                                font-weight: 500;

                                svg {
                                    color: var(--txt-white);
                                    font-size: 20px;
                                }
                            }

                            .active_Link {
                                background-color: var(--primary-color);
                            }



                            &::after {
                                margin-right: 15px;
                                background-image: var(--arrow-img);
                            }
                        }
                    }

                    .only_button {
                        .accordion-button {
                            padding: 0px;

                            &::after {
                                display: none;
                            }
                        }
                    }

                    .accordion-body {
                        padding: 10px;
                        background-color: var(--sidebar-bg);

                        ul {
                            padding-left: 0px;
                            list-style: none;

                            li {
                                margin: 5px 0px;

                                a {
                                    background-color: transparent;
                                    width: 100%;
                                    display: flex;
                                    padding: 10px;
                                    color: var(--txt-white);
                                    font-size: 14px;
                                    border-radius: 4px;
                                    font-weight: 500;
                                    opacity: .7;
                                    text-decoration: none;
                                    margin-left: 27px;

                                    &:hover {
                                        // background-color: var(--sidebar-hover);
                                        color: var(--sky-blue);
                                    }
                                }

                                .nav_link_after {
                                    background-color: salmon;
                                }

                            }
                        }
                    }
                }
            }

        }



    }


    .react-responsive-modal-closeButton {
        position: absolute;
        top: 19px !important;
        right: 14px !important;
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        display: flex;

        &:focus-visible {
            outline: none !important;
        }

        svg {
            fill: var(--txt-white);
        }
    }

}

body[homeheader="true"] {
    header {
        background-color: transparent !important;
        backdrop-filter: blur(10px) !important;

        .left-header{
            .token__sx {
                background-color: rgb(255 255 255 / 20%);
                border: 1px solid #ffffff29;
                color: #fff;
            }
            .navbar-logo img:last-child{
                display: none;
            }

            .navbar-logo img:first-child{
                display: block;
            }

        }

        .right-header {
            .nav {
                .nav-item {
                    .dropdown__sx .dropdown_menu_sx .dropdown-ol {
                        box-shadow: unset;
                    }

                    .nav-link {
                        color: #fff;

                    }
                }
            }

            .right__end__sx .theme__button {
                color: #fff;
            }
        }


    }
}