.main___next {
    margin-top: 130px;

    .trans__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;

        @media ($breakpoint_MD){
            flex-direction: column;
            align-items: flex-start;
        }

        .trans__header__jdhf{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            .copy__btn_searchbar{
                padding: 4px;
                display: inline-block;
                font-size: 12px;
                cursor: pointer;
                color: var(--txt-black);

                svg{
                    font-size: 15px;
                }

            }

        }

        .trans__header_txt {
            margin-bottom: 0;
            font-weight: 600;

            @media ($breakpoint_MD){
                margin-bottom: 10px;
            }

            @media ($breakpoint_SM){
                font-size: 5vw;
            }

            .address__as{
                font-size: 15px;
            }
        }

        .search__bar {
            max-width: 500px;
            width: 100%;

            


            form {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 7px 10px;
                border-radius: 10px;
                overflow: hidden;
                background-color: var(--input__bg);

                @media ($breakpoint_MD){
                    padding: 0 10px;
                }


                input {
                    width: 100%;
                    padding: 12px;
                    background-color: transparent;
                    box-shadow: unset;
                    border: unset;
                    color: var(--txt-black);

                    &::placeholder {
                        color: var(--txt-black);
                    }
                }

                .search__btn {
                    background-color: var(--primary-color);
                    color: #fff;
                }

            }

        }

    }

    .stickyTop_j {
        position: sticky;
        top: 0;
        z-index: 5;
        background-color: var(--a-header);
        box-shadow: var(--a-header-box-shadow);

        form {
            background-color: var(--chart__sec) !important;
        }

    }

    .card___section {
        padding: 0 20px;
        margin: 20px 0;

        .row{
            row-gap: 20px;
        }

        .trans__card_details {
            padding: 20px 20px;
            border-radius: 10px;
            position: relative;
            background-color: var(--q_card);
            color: var(--txt-black);
            border: 1px solid var(--q_card_border);
            height: 100%;

            &:hover{
                h6{
                    display: block;
                }
            }

            p {
                font-size: 14px;
                text-transform: capitalize;
                margin-bottom: 3px;
            }

            h5{
                margin-bottom: 0;
            }

            h6 {
                position: absolute;
                top: 10px;
                right: 20px;
                margin-bottom: 0;
                display: none;
                opacity: 0.7;
            }

        }


    }


    .table__box {
        padding: 0 20px;


        .table__outer {
            margin-top: 30px;
            margin-bottom: 80px;


            .table__outer__header {
                @include display(space-between, center);
                padding-bottom: 20px;


                .table__txt-header {

                    h5 {
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 0;
                        opacity: 0.8;
                    }
                }

            }





            .table {
                --font_table__size: 15px;
                --id__width: 130px;
                margin-bottom: 0;

                @media($breakpoint_LG) {
                    width: 1200px;
                }


                thead {
                    z-index: 1;

                    tr {
                        th {
                            font-size: 14px;
                            font-weight: normal;
                            text-transform: capitalize;
                            padding: 10px 0;
                            border-color: var(--table_border);
                            background-color: transparent;
                            color: var(--txt-black);
                            font-weight: 600;



                            &:nth-child(9) {
                                svg {
                                    margin-right: 20px;

                                }
                            }


                            svg {
                                font-size: 16px;

                            }

                            h5 {
                                display: flex;
                                align-items: center;
                                font-weight: normal;
                                text-transform: capitalize;
                                font-size: 14px;
                                margin-bottom: 0;

                                svg {
                                    margin-left: 5px;
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }

                .tnx_list {
                    color: var(-blue);


                    tr {

                        &:last-child {
                            td {
                                border-color: transparent !important;
                            }
                        }

                        &:hover {
                            background-color: var(--hover-tr);
                        }

                        .link_tag {
                            color: var(--table_link);
                            text-decoration: none;
                        }

                        &:last-child {
                            padding-bottom: 20px;
                        }

                        td {
                            font-size: var(--font_table__size);
                            color: var(--txt-black);
                            white-space: nowrap;
                            vertical-align: middle !important;
                            padding: 10px 0;
                            border-color: var(--table_border);
                            background-color: transparent !important;
                            box-shadow: none !important;





                            h5 {
                                margin-bottom: 0;
                            }

                            // Tooltips 

                            .copy__value {
                                white-space: nowrap !important;
                            }

                            .popover {
                                color: blue;
                                background-color: salmon !important;

                                .popover-header {
                                    background-color: #dcdcdc42;
                                    color: blue;

                                }
                            }




                            &:nth-child(1) {
                                padding: 10px 0 !important;

                                .eye__button {
                                    @include display(center, center);
                                    padding: 7px !important;
                                    background-color: var(--table_status);
                                    border: 1px solid var(--table_status_border);

                                    color: var(--txt-black);
                                    border-radius: 6px;
                                    cursor: pointer;
                                    text-align: center;

                                    svg {
                                        display: block;
                                        font-size: 12px;
                                    }
                                }

                            }

                            &:nth-child(2) {

                                h5 {
                                    white-space: nowrap;
                                    width: 100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: var(--font_table__size);
                                }
                            }


                            &:nth-child(3) {
                                .button_d {
                                    background-color: var(--table_status);
                                    border: 1px solid var(--table_status_border);
                                    color: var(--txt-status__btn);
                                    text-align: center;
                                    border-radius: 4px;
                                    padding: 1px 16px !important;
                                    font-size: 13px !important;
                                    margin: 0 auto;
                                    transition: none;
                                }
                            }

                            &:nth-child(4) {
                                h5 {
                                    font-size: var(--font_table__size);
                                    font-weight: normal;
                                }
                            }

                            &:nth-child(5) {
                                .times-area {
                                    @include display(space-between, center);

                                    .time {
                                        display: inline;
                                    }

                                    .time-date {
                                        display: none;
                                    }
                                }
                            }

                            &:nth-child(6) {
                                padding: 10px 0 !important;

                                .id__from_token {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    border-radius: 6px;
                                    padding: 0 5px;
                                    width: var(--id__width);


                                    h5 {
                                        font-size: 14px;
                                        font-weight: normal;
                                        font-size: var(--font_table__size);
                                    }

                                    .copy-token {
                                        margin-left: 10px;
                                        padding: 3px 5px !important;
                                        outline: none;
                                        box-shadow: none;
                                        border: none;
                                        background-color: transparent;
                                        border: 0.9px solid transparent;
                                        border-radius: 3px;
                                        color: var(--txt-black);


                                        &:hover {
                                            border-color: var(--primary-color);
                                        }

                                        svg {
                                            font-size: 15px;
                                        }
                                    }
                                }

                            }


                            &:nth-child(7) {

                                .TOP__riht {
                                    @include display(flex-start, center);

                                    .this_TO_this {
                                        background-color: var(--table_status);
                                        border: 1px solid var(--table_status_border);
                                        color: var(--txt-status__btn);
                                        padding: 7px 5px;
                                        border-radius: 30px;
                                        @include display(center, center);
                                        margin: 0 10px;
                                        width: 30px;

                                        svg {
                                            font-size: 14px;
                                        }


                                    }
                                }

                            }

                            &:nth-child(8) {
                                padding: 10px 0 !important;

                                .id__from_token {
                                    @include display(space-between, center);
                                    border-radius: 6px;
                                    padding: 0 5px;
                                    width: var(--id__width);

                                    h5 {
                                        font-size: var(--font_table__size);
                                        font-weight: normal;
                                        @include display(space-between, center);

                                        svg {
                                            margin-right: 10px;
                                        }

                                    }

                                    .copy-token {
                                        margin-left: 10px;
                                        padding: 3px 5px !important;
                                        outline: none;
                                        box-shadow: none;
                                        border: none;
                                        background-color: transparent;
                                        border: 0.9px solid transparent;
                                        border-radius: 3px;
                                        color: var(--txt-black);

                                        &:hover {
                                            border-color: var(--primary-color);
                                        }

                                        svg {
                                            font-size: 15px;
                                        }
                                    }
                                }

                            }

                            &:nth-child(9) {

                                h5 {
                                    margin-right: 20px;
                                    font-size: var(--font_table__size);
                                    font-weight: normal;

                                }
                            }

                            &:nth-child(10) {
                                h5 {
                                    font-size: var(--font_table__size);
                                    font-weight: normal;
                                }
                            }


                        }
                    }
                }

                .blocks_list{
                    tr{
                       
                        &:last-child {
                            td {
                                border-color: transparent !important;
                            }
                        }

                        &:hover {
                            background-color: var(--hover-tr);
                        }

                        .link_tag {
                            color: var(--table_link);
                            text-decoration: none;
                        }


                        td {
                            font-size: var(--font_table__size);
                            color: var(--txt-black);
                            white-space: nowrap;
                            vertical-align: middle !important;
                            padding: 10px 0;
                            border-color: var(--table_border);
                            background-color: transparent !important;
                            box-shadow: none !important;


                            h5 {
                                font-size: var(--font_table__size);
                                margin-bottom: 0;
                            }

                            // Tooltips 

                            .copy__value {
                                white-space: nowrap !important;
                            }


                            .id__from_token {
                                @include display(flex-start, center);
                                border-radius: 6px;
                                padding: 0 5px;
                                width: var(--id__width);

                                h5 {
                                    font-size: var(--font_table__size);
                                    font-weight: normal;
                                    @include display(space-between, center);

                                    svg {
                                        margin-right: 10px;
                                        font-size: 19px;
                                    }

                                }

                                .copy-token {
                                    margin-left: 15px;
                                    padding: 3px 5px !important;
                                    outline: none;
                                    box-shadow: none;
                                    border: none;
                                    background-color: transparent;
                                    border: 0.9px solid transparent;
                                    border-radius: 3px;
                                    color: var(--txt-black);

                                    &:hover {
                                        border-color: var(--primary-color);
                                    }

                                    svg {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }

                }


            }


            .table__footer {
                @include display(space-between, center);
                border-top: 1px solid var(--table_border);
                padding: 10px 0;

                .show_data {
                    @include display(space-between, center);
                    gap: 10px;

                    .form-select {
                        background-color: transparent;
                        padding: 0.100rem 1.50rem 0.100rem 0.40rem;
                        color: var(--txt-black);
                        box-shadow: unset;
                        outline: none;
                        border-color: var(--table_border);
                    }

                }

            }



        }
    }


}