:root {

    --body: #f6f6f6;
    --txt-white: #fff;
    --txt-black: #000;
    --txt-ddd: #6c757d;
    --primary-color: #ae67d8;
    --btn_gradient: linear-gradient(90deg, #ae67d8, #ffc65b);
    --overview-animation: url(../Images/IMG_ANI/New__BG.png);
    --overview-right-earth: url(../Images/IMG_ANI/right-earth.svg);
    --overview-mass: url(../Images/IMG_ANI/mass.svg);
    --star: url(../Images/IMG_ANI/star.png);
    --over-suni: url(../Images/IMG_ANI/suni.svg);
    --rocket: url(../Images/IMG_ANI/rokat.png);
    --search-dropdown-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    --list-dropdown-border: #9f9e9e21;
    --header__token_sx:rgb(188 188 188 / 20%);
    --header__token_sx_border: #93939329;
    --skeleton_bg: #dcdcdc2b;
    --skeleton_animation_bg: #e7e7e7;



    --input__bg: #fff;
    --input__border: #dcdcdc42;
    --black__border: #00000081;
    --light-bg: #eaebec;



    // all Header and Footer using variable 
    --a-header: #fff;
    --a-header-txt: #fff;
    --dropdown__bg: #fff;
    --dropdown_item_hover: #f4f1ee8c;
    --a-header-box-shadow: 0px 0px 10px 0px #ddd;


    --footer: #fff;
    --footer-border: #dddddd87;
    --footer-media__links: #dddddd87;
    --animation__outer: url(../Images/IMG_ANI/animation-bg.svg);
    --chart__background: url(../Icon/light_mode_logo.png);
    

    // all using card inner scss ...
    --chart__sec: #f6f6f6;
    --q_card: rgb(255, 255, 255);
    --q_card_border:#e9ecef;
    --q_card_footer: #f0ecec;
    --border_table: #dcdcdc81;

    // table top search bar
    --bs-card-box-shadow:0 0.5rem 1.2rem rgb(189 197 209 / 20%);
    --table__searchBar: #fff;
    --table_pagination:#f8f9fa;
    --table_pagination_border:#ddd;
    --table_status:#f8f9fa;
    --table_status_border:#dfe0e1;
    --table_border: #eaecef;
    --table_link:rgb(7 132 195);
    --txt-status__btn: #000;
    --arrowBtn: #eaecef;






}



body[data-theme='dark'] {

    // ,,,,,, 
    --body: #2d2f33;
    --txt-white: #000;   /* * mode change then white variable change to black values  */
    --txt-black: #fffffff7;  /* mode change then black variable change to light values */
    --txt-ddd: #fff;
    --skeleton_bg: #9a959515;
    --skeleton_animation_bg: #6c6868;




    --overview-animation: url(../Images/IMG_ANI/new_bg__.png);
    --overview-right-earth: url(../Images/IMG_ANI/Light_right_earth.svg);
    --overview-mass: url(../Images/IMG_ANI/mass2.svg);
    --over-suni: url(../Images/IMG_ANI/suni2.svg);
    --star: url(../Images/IMG_ANI/star2.png);
    --rocket: url(../Images/IMG_ANI/rocket2.png);
    --chart__background: url(../Icon/dark__logo__gtc.png);




    // all header and footer variable
    --a-header: #282828;
    --a-header-txt: #fff;
    --dropdown__bg: #282828;
    --dropdown_item_hover: #f4f1ee36;
    --a-header-box-shadow: 0px 0px 10px 0px #7c787855;





    // top animation section 
    // --animation__outer: #0d1116;
    --input__bg: #282828;


    // index variable
    --chart__sec: #1a1a1a;
    --q_card: #282828;
    --q_card_border: transparent ;
    --q_card_footer: #353434;
    --border_table: #57545481;



    // Footer Links work
    --footer: #2b2b2b;
    --footer-border: #585656;
    --footer-media__links: #2b2b2b;

    // table inner section
    --table_pagination : transparent;
    --table_pagination_border:#555353;
    --table_status:#151515;
    --table_status_border:#555353;
    --table_link:rgb(13 174 255);
    --table_border:#414142;
    --txt-status__btn: #ffffffbb;
    --arrowBtn: #222222;





}