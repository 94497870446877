// @import 'bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/bootstrap';
@import 'react-loading-skeleton/dist/skeleton.css';
@import './_theme.scss'; // Only css variable ( color acc useing__ )...
@import './Common.scss'; // Common scss ( breakpoints , mixin, common button , common list, common Heading size and much more )

// ? Please Login and signUp more page ( New create new scss file then import_)


// layout scss___
@import './Layout/Header.scss';
@import './Layout/Footer.scss';


// more page scsss___
@import './error.scss';
@import './index.scss';
@import './tables.scss';
@import './BlockDetails.scss';
@import './addressview.scss';
