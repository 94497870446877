.default_card_common {
    width: 100%;
    background-color: var(--q_card);
    color: var(--txt-black);
    padding: 20px;
    border: 1px solid var(--q_card_border);
    border-radius: 10px;
}

.main___next {

    .card_outer_blocks {
        padding: 0 20px;

        @media ($breakpoint_SM){
            padding: 0 15px;
        }

    }


    .blocks__card {
        margin: 50px 0 20px;
        --font__block_size: 15px;
        --icon__size: 17px;

        .block__sd {
            padding-bottom: 15px;
            border-bottom: 1px solid var(--border_table);
        }

        .last__block__sd {
            padding-bottom: 0;
            border: 0;
            margin-bottom: 0 !important;
        }


        ul {
            padding: 0;

            li {
                @include display(flex-start, center);
                padding-bottom: 20px;


                @media ($breakpoint_MD){
                    flex-direction: column;
                    align-items: flex-start;
                }



                p {
                    margin-bottom: 0;
                    font-size: var(--font__block_size);
                    color: var(--txt-black);

                    svg {
                        font-size: var(--icon__size);
                        color: var(--txt-black);
                    }
                }

                .left_heading {
                    width: 35%;
                    font-size: var(--font__block_size);
                    color: var(--txt-ddd);

                    @media ($breakpoint_MD){
                        margin-bottom: 6px;
                        width: 100%;
                        color: var(--txt-black);
                        font-weight: 600;
                    }
                    svg {
                        margin-right: 7px;
                        font-size: var(--icon__size);
                    }

                    .active__svg{
                        color: var(--table_link);
                    }
                }

                .inner__link {
                    color: var(--table_link);
                    text-decoration: none;
                    font-size: var(--font__block_size);
                    font-weight: 600;
                    svg{
                        color: var(--table_link);

                    }
                }

                .right__a {
                    width: 100%;
                    @include display(flex-start, center);

                   

                    .badge__btn{
                        color: var(--txt-ddd);
                        padding: 3px 8px;
                        border-radius: 5px;
                        font-size: 13px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 4px;


                        svg{
                            font-size: 13px;
                        }
                    }

                    .success-badge{
                        color: #00a186;
                    }

                    .copybtn {
                        padding: 3px;
                    }


                    .arrow__btn_group {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 12px;
                        margin-left: 20px;

                        .arrow__btn {
                            background-color: var(--arrowBtn);
                            padding: 0px;
                            border-radius: 5px;
                            display: block;
                            font-size: var(--font__block_size);
                            color: var(--txt-ddd);

                        }

                    }

                    .extradate__txt {
                        background-color: var(--chart__sec);
                        box-shadow: unset;
                        font-size: var(--font__block_size);
                        height: 200px;
                        color: var(--txt-black);
                        border-color: var(--border_table);
                    }
                    .extradate__txt_2{
                        height: 75px !important;
                    }
                }
            }
        }

        .onclick_btn {
            cursor: pointer;

            .right__a {
                p{
                    color: var(--table_link);
                }
            }


        }

        .collapseData {
            height: 0;
            overflow: hidden;
            transition: all 0.3s linear;
        }

        .inff {
            display: flex;
            flex-direction: column;
        }

        .collaspace__data {
            flex-direction: column-reverse;

            .onclick_btn {
                border-top: 1px solid var(--border_table);
                padding-top: 20px;

                



            }

            .collapseData {
                height: 186px !important;

                @media ($breakpoint_MD){
                    height: 306px !important;
                }
            }

            .collapseData__2{
                height: 316px !important;
            }
        }

    }

    .tip__sec{
        font-size: 13px;
        color: var(--txt-ddd);
        margin-bottom: 80px;
        padding-left: 5px;

        svg{
            font-size: 20px;
        }

        .inner__link {
            color: var(--table_link);
            text-decoration: none;
            font-size: var(--font__block_size);
            font-weight: 600;
            svg{
                color: var(--table_link);
            }
        }
    }
}