* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,100;1,9..40,200;1,9..40,300&family=Lato:wght@300;400;700;900&family=Outfit:wght@200;300;400;500;600;700;800;900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Black+Ops+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Scribble&family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// font-family: 'DM Sans', sans-serif;
// font-family: 'Lato', sans-serif;
// font-family: 'Outfit', sans-serif;
// font-family: 'Titillium Web', sans-serif;
//   font-family: "PT Sans", sans-serif;
// font-family: "Black Ops One", system-ui;
// font-family: "Archivo Black", sans-serif;
// font-family: "Teko", sans-serif;
// font-family: "Rubik Scribble", system-ui;
// font-family: "Poppins", sans-serif;



$font_heading: 'Titillium Web',sans-serif;
$font_pragph: 'DM Sans',sans-serif;
$font_btn: 'Outfit', sans-serif;




//Breakpoints....
$breakpoint_XXXL: 'max-width: 1500px';
$breakpoint_XXL: 'max-width: 1400px';
$breakpoint_XL: 'max-width: 1200px';
$breakpoint_LG: 'max-width: 992px';
$breakpoint_MD: 'max-width: 768px';
$breakpoint_SM: 'max-width: 576px';
$breakpoint_450: 'max-width: 450px';
$breakpoint_ESM: 'max-width: 385px';
$breakpoint_50: 'max-width: 350px';



$overview-animation: var(--overview-animation);
$overview-right-earth: var(--overview-right-earth);
$overview-mass: var(--overview-mass);
$suni__imgas: var(--over-suni);
$animation-txt: var(--animation-txt);
$star: var(--star);
$rotet_BG: var(--rocket);


body {
    background-color: var(--body);
    color: var(--txt-black);
    font-family: $font_heading;
}


.table__card {
    width: 100%;
    background-color: var(--q_card);
    color: var(--txt-black);
    padding: 20px 15px 10px;
    border: 1px solid var(--q_card_border);
    border-radius: 10px;
}


@mixin display($justify-content, $align-items) {
    display: flex;
    justify-content: $justify-content;
    align-items: $align-items;

}

.pagination__sx {
    ul {
        --paddig_pagination : 3px 7px;
        @include display(space-between, center);
        list-style-type: none;
        margin-bottom: 0;

        li {
            font-size: 14px;
            padding: 5px;
            background-color: transparent;


            a {
                border: 1px solid var(--table_pagination_border);
                padding: var(--paddig_pagination);
                font-size: 13px;
                display: block;
                color: var(--txt-black);
                background-color: var(--table_pagination);
                transition: none;

                span{
                    svg{
                        font-size: 13px;
                    }
                }

                &:focus,
                &:hover {
                    background-color: transparent;
                    color: var(--primary-color);
                    border-color: var(--table_pagination_border);
                    box-shadow: unset !important;
                }
            }
        }

        .disabled {
            span {
                padding: 4px 7px;
                font-size: 13px;
                border: 1px solid var(--table_pagination_border);
                background-color: var(--table_pagination);
                color: var(--txt-black);
                transition: none;



            }
        }
    }

}



.Table-skeleton {
    --highlight-color: #f5f5f54f;
    width: 100%;


    .slider-loading-skeleton {
        height: 20px;
        background-color: var(--skeleton_bg) !important;
        border-radius: 10px !important;

        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }

        &::after {
            background: var(--skeleton_animation_bg) !important;

        }

    }

    .home__skeleton {
        height: 15px !important;
    }

    .home__skeleton2 {
        height: 10px !important;
    }



}

.table__6__ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .home_skeleton__hn {
        height: 16px;
        width: 100px;
        background-color: var(--skeleton_bg) !important;
        border-radius: 10px !important;
        display: block;
        margin-bottom: 10px;

        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }

        &:nth-last-child(2) {

            margin-bottom: 0;
        }

        &::after {
            background: var(--skeleton_animation_bg) !important;

        }
    }

    .home_skeleton__center{
        width: 70%;
    }

    br{
        display: none;
    }


}

.avatar-skeleton{
    height: 39px;
    width: 55px;
    position: relative;
    top: -2px;
    margin-right: 15px;

    .react-loading-skeleton{
        background-color: var(--skeleton_bg) !important;
        border-radius: 6px !important;


        &::after{
            background: var(--skeleton_animation_bg) !important;
        }
    }
}

.avatar-skeleton2{
    width: 43px !important;
}


.MainTable-skeleton {
    --highlight-color: #f5f5f54f;
    width: 100%;


    .skeleton__lines{
        height: 30px;
        background-color: var(--skeleton_bg) !important;
        border-radius: 10px !important;
        margin-bottom: 14px;

        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }

        &::after {
            background: var(--skeleton_animation_bg) !important;

        }

    }
}