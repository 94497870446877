@import './animation.scss';




.chart__sec {
    padding: 20px 40px 100px;
    background-color: var(--chart__sec);

    .main__row {
        row-gap: 20px;
    }

    .label__link-dzbd7lyV,
    .tradingview-widget-copyright {
        display: none !important;
    }

    .chart___iframe {
        height: 100%;

        iframe {
            height: 47px;
            width: 100%;
        }

    }


    .default_card {
        width: 100%;
        background-color: var(--q_card);
        color: var(--txt-black);
        padding: 10px;
        border: 1px solid var(--q_card_border);
        border-radius: 10px;
    }

    .tv-embed-widget-wrapper__body {
        border: 0 !important;
    }

    .tv-embed-widget-wrapper__body {
        background-color: transparent !important;
    }


    // chart Logo..
    .chart__card_sx {
        position: relative;
        z-index: 1;
        // overflow: hidden;
        color: #000 !important;

        p {
            display: block;
            margin-left: 10px;
            margin-bottom: 0;
            color: var(--txt-black) !important;

        }


        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70%;
            height: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            background-image: var(--chart__background);
            object-fit: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.4;
        }


        #SvgjsText1153 {
            font-size: 22px;
            font-family: $font_heading !important;
            font-weight: 600 !important;
            fill: var(--txt-black) !important;
        }

        #SvgjsText1286 {
            fill: var(--txt-black) !important;

        }

        #SvgjsG1248 {
            text {
                fill: var(--txt-black) !important;

            }
        }

        .apexcharts-xaxis {
            .apexcharts-xaxis-texts-g {
                fill: var(--txt-black) !important;

            }

        }

        .apexcharts-text tspan {
            fill: var(--txt-black) !important;

        }
    }


    .info__row {
        row-gap: 20px;
    }

    .price__card {
        padding: 20px;
        font-family: $font_pragph;

        .card-header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: transparent;
            padding: 0;
            border: 0;

            .card__icon {
                padding: 5px;
                border-radius: 10px;
                background: var(--btn_gradient);
                margin-right: 20px;


                img {
                    width: 50px;
                    object-fit: contain;
                }

                svg {
                    color: var(--txt-white);
                    font-size: 46px;
                    object-fit: contain;

                }

            }

            .card__icon_2 {
                padding: 10px;

                img {
                    width: 37px;
                }
            }

            .card-header-rd {
                font-family: "PT Sans", sans-serif;
                width: 100%;

                p {
                    margin-bottom: 0;
                }
            }


        }

        .card-body {
            background-color: var(--chart__sec);
            border-radius: 10px;
            margin-top: 20px;
            padding: 15px 15px;

            ul {
                padding: 0;
                list-style-type: none;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 0;

                li {
                    width: 100%;

                    p {
                        margin-bottom: 0;
                        font-size: 14px;
                    }
                }
            }
        }

        .card_body__height {
            height: 100px;
        }
    }

    .tabes__card {
        --padding__table__li: 17px 20px;
        --font__table_size: 14px;
        padding: 0;
        font-family: "Poppins", sans-serif;
        overflow: hidden;


        .card-header {
            background-color: transparent;
            padding: var(--padding__table__li);
            border-color: var(--border_table);

            p {
                margin-bottom: 0;
            }
        }

        .card-body {
            padding: 0 20px;

            .table__list {
                padding: 0;
                list-style-type: none;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: var(--padding__table__li);
                    border-bottom: 1px solid var(--border_table);

                    &:last-child {
                        border: 0;
                    }

                    .inner__link {
                        text-decoration: none;
                        color: var(--primary-color);
                    }

                    .i_first {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .icon__first {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: var(--chart__sec);
                            color: var(--txt-black);
                            height: 40px;
                            width: 40px;
                            margin-right: 20px;
                            border-radius: 6px;

                            img {
                                width: 100%;
                            }
                        }

                        .i_pragraph {
                            p {
                                font-size: var(--font__table_size);
                                margin-bottom: 0;
                            }
                        }
                    }

                    .i_middle {
                        width: 50%;
                        text-align: center;

                        p {
                            font-size: var(--font__table_size);
                            margin-bottom: 0;
                        }
                    }

                    .i_last {
                        p {
                            font-size: 13px;
                            border-radius: 6px;
                            margin-bottom: 0;
                            display: inline-block;
                            padding: 6px 10px;
                            border: 1px solid var(--border_table);
                            font-weight: 600;
                            line-height: 15px;
                        }

                    }

                }


            }

        }

        .card-footer {
            background-color: var(--q_card_footer);
            padding: 0;
            border: 0;

            a {
                padding: var(--padding__table__li);
                display: block;
                text-align: center;
                color: var(--txt-black);
                text-decoration: none;
                font-size: 13px;

                svg {
                    margin-left: 10px;
                }
            }


        }

    }

}