main {

    // $animation_outer: var(--animation__outer)

    .top-section-overview {
        padding: 150px 30px 50px;
        // overflow: hidden;
        position: relative;
        z-index: 5;
        color: var(--txt-white);
        font-family: $font_heading;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--animation__outer) !important;
            background-position: center;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            z-index: -3;
            transform: rotate(180deg);
        }

        @media($breakpoint_MD) {
            flex-direction: column;
            justify-content: left;
            align-items: start;
            padding: 150px 15px 50px;

        }
        
        @media($breakpoint_SM) {
            padding: 150px 10px 50px !important;
        }

       

        .animation_contianer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            overflow: hidden;

        }



       


        .overview-sec {
            width: 100%;
            color: var(--a-header-txt);

        }


        .new__animation_data {
            max-width: 700px;
            width: 100%;


            @media ($breakpoint_LG){
                max-width: 100%;
                text-align: center;
                padding: 0 40px;
            }

            h2 {
                font-weight: 600;
            }
        }

        .right-top-sec {

            form {
                padding: 20px;
                border-radius: 10px;
                background-color: var(--header__token_sx);
                border: 1px solid var(--header__token_sx_border);


                @media ($breakpoint_MD){
                    padding: 10px;
                }

                .form___outer {
                    display: flex;
                    align-items: stretch;
                    justify-content: flex-start;
                    background-color: var(--input__bg);
                    border: 1px solid var(--input__border);
                    border-radius: 8px;




                    .INpu__searhc {
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                        width: 150px;
                        position: relative;
                        z-index: 1;

                        input {
                            padding: 5px 5px 5px 10px !important;
                            border: unset !important;
                            outline: unset !important;
                            width: 100%;

                        }

                        span {

                            svg {
                                color: var(--txt-black);
                            }
                        }
                    }

                }



                .form-group {
                    padding: 5px 5px 5px 10px;
                    display: flex;
                    justify-content: space-between;
                    background-color: transparent;
                    align-items: center;
                    position: relative;
                    z-index: 11;
                    width: 100%;

                }




                label {
                    margin-bottom: 0;
                    color: var(--txt-black);
                }



                input {
                    background-color: transparent;
                    border: unset;
                    color: var(--txt-black);
                    box-shadow: none;
                    padding-left: 0;

                    &::placeholder {
                        color: var(--txt-black);

                    }
                }

                .search-type {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background-color: var(--dropdown__bg);
                    padding: 0;
                    border-radius: 9px;
                    height: auto;
                    z-index: -1;
                    font-size: 14px;
                    margin-top: 5px;
                    border: 1px solid var(--header__token_sx_border);
                    box-shadow: var(--search-dropdown-shadow);
                    display: none;

                    .list-group-item {
                        background-color: transparent;
                        color: var(--txt-black);
                        border: 0;
                        border-bottom: 1px solid var(--list-dropdown-border);
                        margin-bottom: 5px;
                        border-radius: 3px !important;
                        white-space: nowrap;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;

                        &:last-child {
                            margin-bottom: 0;
                        }

                    }

                }

                .input__sarch_type:focus+.search-type {
                    display: block;
                }

                .search-dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background-color: var(--dropdown__bg);
                    padding: 15px;
                    border-radius: 9px;
                    height: auto;
                    z-index: -1;
                    font-size: 14px;
                    margin-top: 5px;
                    border: 1px solid var(--header__token_sx_border);
                    box-shadow: var(--search-dropdown-shadow);
                    display: none;




                    a {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                    }

                    .badege-link {
                        margin-left: 10px;
                        background-color: transparent !important;
                        border: 1px solid var(--list-dropdown-border);
                        color: var(--txt-black) !important;
                    }

                    .list-group-item {
                        background-color: transparent;
                        color: var(--txt-black);
                        border: 1px solid var(--list-dropdown-border);
                        margin-bottom: 5px;
                        border-radius: 3px !important;

                        &:last-child {
                            margin-bottom: 0;
                        }

                    }



                    @media (height:100vh) {
                        max-height: calc(80vh - 100px);
                        overflow: auto !important;
                        overflow-y: scroll;
                        overflow-x: hidden;
                    }



                }

                input:focus+.search-dropdown {
                    display: block;
                }


            }

        }
    }

}



.THECD676 {
    position: absolute;
    top: 0;
    right: 20px;
    width: 250px;
    height: 250px;
    z-index: -1;
    background: $overview-right-earth;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    animation: top-down 22s linear infinite;


    @media($breakpoint_SM) {
        width: 90px;
        height: 90px;

    }


    @keyframes top-down {
        0% {
            transform: translateY(100%);
        }

        50% {

            transform: translateY(-20%);
        }

        100% {
            transform: translateY(100%);


        }

    }
}


.LEft___S {
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 160px;
    height: 160px;
    z-index: -1;
    background: $overview-mass;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    animation: top-down_left 11s linear infinite;



    @media($breakpoint_SM) {
        width: 90px;
        height: 90px;
        left: 10px;

    }

    @keyframes top-down_left {
        0% {
            left: 0%;
            transform: rotate(0deg);

        }

        25% {
            bottom: 30%;
            transform: rotate(100deg)
        }

        50% {
            bottom: 0;
            left: 100%;
            transform: rotate(200deg);

        }

        75% {
            bottom: -30%;
            transform: rotate(250deg);

        }

        100% {
            background: none;
            left: 0%;
            transform: rotate(150deg);
        }

    }



}


.LEFT__SDD {

    position: absolute;
    top: 0px;
    left: 180px;
    width: 80px;
    height: 200px;
    // z-index: -1;
    background: $suni__imgas;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    animation: LEFT__RIGHT_SFZSF 22s linear infinite;
    // transition-delay: 0.9s;
    z-index: -2;

    @media($breakpoint_SM) {
        width: 40px;
        height: 100px;
        left: 100px;

    }

    @keyframes LEFT__RIGHT_SFZSF {
        0% {
            transform: translateY(-60px);
        }

        30% {
            transform: translateY(40%) rotate(-40deg);
        }

        52% {
            transform: translateY(-14px);
        }

        80% {
            transform: translateY(40%) rotate(-40deg);
        }

        100% {
            transform: translateY(-60px);
        }

    }



}


.LEFT__STAR {
    // position: absolute;
    top: 0;
    right: 20px;
    width: 80px;
    height: 60px;
    z-index: 1;
    background: $star;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    animation: svelte-vts2a8-star_move 5s linear infinite;


    @media($breakpoint_MD) {
        position: absolute;
    }


    @keyframes svelte-vts2a8-star_move {
        0% {
            transform: translate(100%, -30%);
            opacity: 0
        }

        30% {
            opacity: .65
        }

        70% {
            opacity: .65
        }

        to {
            opacity: 0;
            transform: translate(-100%, 130%)
        }
    }



}

.bg__SDS {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none;
}

.bg__star {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: -1;
    width: 100%;

    .star___droping {

        &:nth-child(odd) {
            // background: rgb(179, 255, 0);
            animation: svelte-vts2a8-star_move 11s 5s linear infinite !important;

        }

        &:nth-child(even) {
            // background: rgb(179, 255, 0);
            animation: svelte-vts2a8-star_move 0.2 5s linear infinite !important;

        }

    }



}

.bg__star_2 {
    // position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-wrap: wrap;
    z-index: -1;
    width: 50%;
    transition-delay: 5.999999s;
}

.LEFT__STAR_relative {
    position: relative !important;
}

.LEFT__STAR_2 {
    // position: relative;
    top: 0;
    left: 0;
    width: 80px;
    height: 60px;
    z-index: -1;

    @keyframes svelte-vts2a8-star_move {
        0% {
            transform: translate(100%, -80%);
            opacity: 0
        }

        30% {
            opacity: .65
        }

        70% {
            opacity: .65
        }

        to {
            opacity: 0;
            transform: translate(-100%, 180%)
        }
    }

}


.Rocket__ani {
    // background-color: salmon;
    position: absolute;
    bottom: -70px;
    left: 50%;
    width: 200px;
    height: 100px;
    background: $rotet_BG;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    animation: svelte-1izszfg-rocket_move 16s linear infinite;
    z-index: -1;

    $rotet: -90deg;
    transform: rotate($rotet);


    @keyframes svelte-1izszfg-rocket_move {
        0% {
            transform: rotate($rotet) translate(-40%);
        }

        to {
            transform: rotate($rotet) translate(200%, -400%);
        }
    }


}


.Rocket__ani_2 {
    position: absolute;
    bottom: -120px;
    left: 90%;
    width: 200px;
    height: 100px;
    background: $rotet_BG;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    animation: svelte-1izszfg-rocket_move_1 16s 11s linear infinite;
    z-index: -1;
    $rotet: -90deg;
    transform: rotate($rotet);
    transition-delay: 5s !important;


    @keyframes svelte-1izszfg-rocket_move_1 {
        0% {
            transform: rotate($rotet) translate(-40%);
        }

        to {
            transform: rotate($rotet) translate(200%, -400%);
        }
    }


}





.star___star {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: -42vw -4vh 0px 0px #fff, 25vw -41vh 0px 0px #fff,
        -20vw 49vh 0px 1px #fff, 5vw 40vh 1px 1px #fff, 29vw 19vh 1px 0px #fff,
        -44vw -13vh 0px 0px #fff, 46vw 41vh 0px 1px #fff, -3vw -45vh 0px 1px #fff,
        47vw 35vh 1px 0px #fff, 12vw -8vh 1px 0px #fff, -34vw 48vh 1px 1px #fff,
        32vw 26vh 1px 1px #fff, 32vw -41vh 1px 1px #fff, 0vw 37vh 1px 1px #fff,
        34vw -26vh 1px 0px #fff, -14vw -49vh 1px 0px #fff, -12vw 45vh 0px 1px #fff,
        -44vw -33vh 0px 1px #fff, -13vw 41vh 0px 0px #fff, -36vw -11vh 0px 1px #fff,
        -23vw -24vh 1px 0px #fff, -38vw -27vh 0px 1px #fff, 16vw -19vh 0px 0px #fff,
        28vw 33vh 1px 0px #fff, -49vw -4vh 0px 0px #fff, 16vw 32vh 0px 1px #fff,
        36vw -18vh 1px 0px #fff, -25vw -30vh 1px 0px #fff, -23vw 24vh 0px 1px #fff,
        -2vw -35vh 1px 1px #fff, -25vw 9vh 0px 0px #fff, -15vw -34vh 0px 0px #fff,
        -8vw -19vh 1px 0px #fff, -20vw -20vh 1px 1px #fff, 42vw 50vh 0px 1px #fff,
        -32vw 10vh 1px 0px #fff, -23vw -17vh 0px 0px #fff, 44vw 15vh 1px 0px #fff,
        -40vw 33vh 1px 1px #fff, -43vw 8vh 0px 0px #fff, -48vw -15vh 1px 1px #fff,
        -24vw 17vh 0px 0px #fff, -31vw 50vh 1px 0px #fff, 36vw -38vh 0px 1px #fff,
        -7vw 48vh 0px 0px #fff, 15vw -32vh 0px 0px #fff, 29vw -41vh 0px 0px #fff,
        2vw 37vh 1px 0px #fff, 7vw -40vh 1px 1px #fff, 15vw 18vh 0px 0px #fff,
        25vw -13vh 1px 1px #fff, -46vw -12vh 1px 1px #fff, -18vw 22vh 0px 0px #fff,
        23vw -9vh 1px 0px #fff, 50vw 12vh 0px 1px #fff, 45vw 2vh 0px 0px #fff,
        14vw -48vh 1px 0px #fff, 23vw 43vh 0px 1px #fff, -40vw 16vh 1px 1px #fff,
        20vw -31vh 0px 1px #fff, -17vw 44vh 1px 1px #fff, 18vw -45vh 0px 0px #fff,
        33vw -6vh 0px 0px #fff, 0vw 7vh 0px 1px #fff, -10vw -18vh 0px 1px #fff,
        -19vw 5vh 1px 0px #fff, 1vw 42vh 0px 0px #fff, 22vw 48vh 0px 1px #fff,
        39vw -8vh 1px 1px #fff, -6vw -42vh 1px 0px #fff, -47vw 34vh 0px 0px #fff,
        -46vw 19vh 0px 1px #fff, -12vw -32vh 0px 0px #fff, -45vw -38vh 0px 1px #fff,
        -28vw 18vh 1px 0px #fff, -38vw -46vh 1px 1px #fff, 49vw -6vh 1px 1px #fff,
        -28vw 18vh 1px 1px #fff, 10vw -24vh 0px 1px #fff, -5vw -11vh 1px 1px #fff,
        33vw -8vh 1px 0px #fff, -16vw 17vh 0px 0px #fff, 18vw 27vh 0px 1px #fff,
        -8vw -10vh 1px 1px #fff;

    /* stars were too big with the layers above but left the code in case no one cares  -- as in, if noone's just that  one other loner who actually cares    */

    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff,
        -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 29vw 19vh 0px 1px #fff,
        4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff,
        -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff,
        4vw 22vh 1px 1px #fff, 36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff,
        -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 5vw 26vh 0px 0px #fff,
        -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff,
        48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff,
        43vw -27vh 1px 1px #fff, 20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff,
        39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, -40vw 45vh 0px 1px #fff,
        11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff,
        -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff,
        42vw 24vh 0px 0px #fff, 45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff,
        -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 40vw 24vh 0px 0px #fff,
        18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff,
        27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff,
        -43vw 13vh 1px 0px #fff, 7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff,
        -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 38vw 25vh 1px 1px #fff,
        -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff,
        48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff,
        21vw 21vh 1px 1px #fff, -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff,
        -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, -48vw 25vh 0px 1px #fff,
        -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff,
        3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff,
        35vw -36vh 1px 1px #fff, 16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff,
        3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, -6vw 21vh 1px 0px #fff,
        48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff,
        -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff,
        29vw -37vh 1px 1px #fff, 28vw 2vh 0px 0px #fff;

    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff,
        -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 29vw 19vh 0px 1px #fff,
        4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff,
        -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff,
        4vw 22vh 1px 1px #fff, 36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff,
        -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 5vw 26vh 0px 0px #fff,
        -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff,
        48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff,
        43vw -27vh 1px 1px #fff, 20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff,
        39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, -40vw 45vh 0px 1px #fff,
        11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff,
        -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff,
        42vw 24vh 0px 0px #fff, 45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff,
        -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 40vw 24vh 0px 0px #fff,
        18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff,
        27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff,
        -43vw 13vh 1px 0px #fff, 7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff,
        -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 38vw 25vh 1px 1px #fff,
        -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff,
        48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff,
        21vw 21vh 1px 1px #fff, -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff,
        -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, -48vw 25vh 0px 1px #fff,
        -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff,
        3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff,
        35vw -36vh 1px 1px #fff, 16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff,
        3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, -6vw 21vh 1px 0px #fff,
        48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff,
        -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff,
        29vw -37vh 1px 1px #fff, 28vw 2vh 0px 0px #fff;


    box-shadow: 24vw 9vh 1px 0px #fff, 12vw -24vh 0px 1px #fff,
        -45vw -22vh 0px 0px #fff, -37vw -40vh 0px 1px #fff, 29vw 19vh 0px 1px #fff,
        4vw -8vh 0px 1px #fff, -5vw 21vh 1px 1px #fff, -27vw 26vh 1px 1px #fff,
        -47vw -3vh 1px 1px #fff, -28vw -30vh 0px 1px #fff, -43vw -27vh 0px 1px #fff,
        4vw 22vh 1px 1px #fff, 36vw 23vh 0px 0px #fff, -21vw 24vh 1px 1px #fff,
        -16vw 2vh 1px 0px #fff, -16vw -6vh 0px 0px #fff, 5vw 26vh 0px 0px #fff,
        -34vw 41vh 0px 0px #fff, 1vw 42vh 1px 1px #fff, 11vw -13vh 1px 1px #fff,
        48vw -8vh 1px 0px #fff, 22vw -15vh 0px 0px #fff, 45vw 49vh 0px 0px #fff,
        43vw -27vh 1px 1px #fff, 20vw -2vh 0px 0px #fff, 8vw 22vh 0px 1px #fff,
        39vw 48vh 1px 1px #fff, -21vw -11vh 0px 1px #fff, -40vw 45vh 0px 1px #fff,
        11vw -30vh 1px 0px #fff, 26vw 30vh 1px 0px #fff, 45vw -29vh 0px 1px #fff,
        -2vw 18vh 0px 0px #fff, -29vw -45vh 1px 0px #fff, -7vw -27vh 1px 1px #fff,
        42vw 24vh 0px 0px #fff, 45vw -48vh 1px 0px #fff, -36vw -18vh 0px 0px #fff,
        -44vw 13vh 0px 1px #fff, 36vw 16vh 0px 1px #fff, 40vw 24vh 0px 0px #fff,
        18vw 11vh 0px 0px #fff, -15vw -23vh 1px 0px #fff, -24vw 48vh 0px 1px #fff,
        27vw -45vh 1px 0px #fff, -2vw -24vh 0px 1px #fff, -15vw -28vh 0px 0px #fff,
        -43vw 13vh 1px 0px #fff, 7vw 27vh 1px 0px #fff, 47vw 5vh 0px 0px #fff,
        -45vw 15vh 1px 1px #fff, -5vw -28vh 0px 1px #fff, 38vw 25vh 1px 1px #fff,
        -39vw -1vh 1px 0px #fff, 5vw 0vh 1px 0px #fff, 49vw 13vh 0px 0px #fff,
        48vw 10vh 0px 1px #fff, 19vw -28vh 0px 0px #fff, 4vw 7vh 0px 0px #fff,
        21vw 21vh 1px 1px #fff, -15vw -15vh 0px 1px #fff, -6vw -42vh 1px 0px #fff,
        -15vw 48vh 1px 1px #fff, -23vw 25vh 1px 1px #fff, -48vw 25vh 0px 1px #fff,
        -31vw -19vh 0px 1px #fff, 4vw 37vh 1px 1px #fff, -43vw 28vh 0px 0px #fff,
        3vw -25vh 0px 1px #fff, -39vw 14vh 0px 1px #fff, -40vw 31vh 0px 1px #fff,
        35vw -36vh 1px 1px #fff, 16vw 49vh 0px 0px #fff, 6vw 39vh 0px 0px #fff,
        3vw -35vh 0px 1px #fff, -44vw -2vh 1px 0px #fff, -6vw 21vh 1px 0px #fff,
        48vw 9vh 1px 1px #fff, -43vw 30vh 1px 1px #fff, 29vw -12vh 1px 1px #fff,
        -48vw 13vh 1px 0px #fff, -42vw 32vh 1px 1px #fff, 34vw 15vh 1px 1px #fff,
        29vw -37vh 1px 1px #fff, 28vw 2vh 0px 0px #fff;
    animation: zoom 16s linear infinite;
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
    }
}